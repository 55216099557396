import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <h1>hi.</h1>
    <p>
      my name is bryn and i'm learning how to code. it's fun to build stuff. I
      wanted to do this for quite a long time, and I'm just now getting around
      to it. I enjoy python quite a bit, but love learning pretty much anything.
      anyway, nice to meet you.
    </p>
    <div>
      <a href="https://github.com/brynphillips">github</a> &nbsp;
      <p>brynphillips1(at)gmail(dot)com</p> &nbsp;
    </div>
  </Layout>
)
